import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const StoryImage = props => (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid( quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        console.log(`Lookig for ${props.filename}`)
        const image = data.images.edges.find(n => {
          return n.node.relativePath.includes(props.filename);
        });
        if (!image) {
          return null;
        }
  
        //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
        return <Img alt={props.alt} fluid={image.node.childImageSharp.fluid} />;
      }}
    />
  );
  
  export default StoryImage;